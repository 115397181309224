import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import * as React from 'react'

import { createContext } from 'react'
import { useWindowSize } from 'usehooks-ts'
import { cn } from '#app/utils/misc'

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip: React.FC<TooltipPrimitive.TooltipProps> = ({
	children,
	...props
}) => {
	const [open, setOpen] = React.useState<boolean>(props.defaultOpen ?? false)
	const windowSize = useWindowSize()
	const isMd = windowSize.width > 768

	return (
		<TooltipPrimitive.Root
			delayDuration={isMd ? props.delayDuration : 0}
			onOpenChange={e => {
				setOpen(e)
			}}
			open={open}
			{...props}
		>
			<TooltipTriggerContext.Provider value={{ open, setOpen }}>
				{children}
			</TooltipTriggerContext.Provider>
		</TooltipPrimitive.Root>
	)
}

const TooltipTrigger = React.forwardRef<
	React.ElementRef<typeof TooltipPrimitive.Trigger>,
	React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Trigger>
>(({ children, ...props }, ref) => {
	const windowSize = useWindowSize()
	const isMd = windowSize.width > 768

	const { setOpen } = React.useContext(TooltipTriggerContext)

	return (
		<TooltipPrimitive.Trigger
			ref={ref}
			{...props}
			onClick={e => {
				!isMd && e.preventDefault()
				setOpen(true)
			}}
		>
			{children}
		</TooltipPrimitive.Trigger>
	)
})

TooltipTrigger.displayName = TooltipPrimitive.Trigger.displayName

const TooltipContent = React.forwardRef<
	React.ElementRef<typeof TooltipPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
	<TooltipPrimitive.Content
		ref={ref}
		sideOffset={sideOffset}
		className={cn(
			'z-50 max-w-64 overflow-hidden rounded-md border border-gray-800 bg-gray-900/90 px-3 py-1.5 text-sm text-popover-foreground text-white shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
			className,
		)}
		{...props}
	/>
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

type TooltipTriggerContextType = {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const TooltipTriggerContext = createContext<TooltipTriggerContextType>({
	open: false,
	setOpen: () => {},
})

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider }
